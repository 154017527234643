// Core
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// Components
import { Form } from "@/client/components/ui/Form";
import { Col } from "@/client/components/ui/Col";
import { Row } from "@/client/components/ui/Row";
import { Button } from "@/client/components/ui/Button";
import { Text } from "@/client/components/ui/Typography/Text";
import { InputForm } from "@/client/components/ui/FormFields/InputForm";
import { BorderDecor } from "@/client/components/ui/BorderDecor";
import { WrappedFormInputSwitch } from "@/client/components/form/wrapped-form-input-switch";
import { ConsentCookiesView } from "@/client/domains/user/consent-cookies/components/consent-cookies-view";

// Definitions
import { ConsentCookiesType } from "@/client/domains/user/consent-cookies/types";

// Utils
import { FormConsentCookiesEnum, consentCookiesForm, inputConfig } from "./config";
import { ROW_GUTTER_TYPE } from "@/client/components/ui/Row/utils";
import st from "./styles.module.css";

type ConsentCookiesFormProps = {
  onAllowAllCookies: () => void;
  onSubmit: (values: ConsentCookiesType) => void;
  testId: string;
  hasDescription?: boolean;
};

export const ConsentCookiesForm = (props: ConsentCookiesFormProps) => {
  const { onAllowAllCookies, onSubmit, hasDescription = false, testId } = props;
  const { t } = useTranslation(["cookies"]);

  const formProps = useForm<ConsentCookiesType>({
    defaultValues: { ...consentCookiesForm.shape },
    resolver: zodResolver(consentCookiesForm.schema()),
    mode: "all",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit, watch } = formProps;

  const handleSubmitForm = handleSubmit((values: ConsentCookiesType): void => {
    onSubmit(values);
  });

  const onSubmitForm = () => {
    void (async () => {
      await handleSubmitForm();
    })();
  };

  const necessaryValue = watch(FormConsentCookiesEnum.necessary);
  const analyticalValue = watch(FormConsentCookiesEnum.analytical);

  const selectedCookiesDataAttribute = `selected_cookies%${necessaryValue ? FormConsentCookiesEnum.necessary : "none"}%${analyticalValue ? FormConsentCookiesEnum.analytical : "none"}%none`;

  return (
    <Form
      name="form-consent-cookies"
      className={st["consent-cookies-form"]}
      onFinish={onSubmitForm}
      size="middle"
      data-testid={testId}
    >
      <div className={st["consent-cookies-form__content"]}>
        <BorderDecor
          gutter="16"
          className={st["consent-cookies-form__cookie"]}
          testId={`${testId}-necessary`}
        >
          <InputForm
            id={FormConsentCookiesEnum.necessary}
            name={FormConsentCookiesEnum.necessary}
            type="no-gutter"
          >
            <WrappedFormInputSwitch
              name={FormConsentCookiesEnum.necessary}
              control={control}
              additionalText={t(inputConfig[FormConsentCookiesEnum.necessary].label)}
              textAlign="right"
              disabled
            />
          </InputForm>
          {hasDescription && (
            <ConsentCookiesView cookieType="necessary" description={t("necessary.description")} />
          )}
        </BorderDecor>
        <BorderDecor gutter="16" testId={`${testId}-analytical`}>
          <InputForm
            id={FormConsentCookiesEnum.analytical}
            name={FormConsentCookiesEnum.analytical}
            type="no-gutter"
          >
            <WrappedFormInputSwitch
              name={FormConsentCookiesEnum.analytical}
              control={control}
              additionalText={t(inputConfig[FormConsentCookiesEnum.analytical].label)}
              textAlign="right"
            />
          </InputForm>
          {hasDescription && (
            <ConsentCookiesView cookieType="analytical" description={t("analytical.description")} />
          )}
        </BorderDecor>
      </div>
      <Row
        gutter={ROW_GUTTER_TYPE.md.horizontal}
        justify="end"
        className={st["consent-cookies-form__actions"]}
      >
        <Col xs={24} md={7} lg={5} className={st["consent-cookies-form__button"]}>
          <Button
            block
            size="large"
            htmlType="submit"
            data-kmtx-cookies-allow_customized={selectedCookiesDataAttribute}
          >
            <Text fontWeight="inherit">{t("consentCookies.buttons.allowSelectionCookies")}</Text>
          </Button>
        </Col>
        <Col xs={24} md={7} lg={5} className={st["consent-cookies-form__button"]}>
          <Button block size="large" type="primary" onClick={onAllowAllCookies}>
            <Text color="inherit" fontWeight="inherit">
              {t("consentCookies.buttons.allowCookies")}
            </Text>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
