// Utils
import { CONSENT_COOKIE_KEY } from "../constants";
import { cookiesService } from "@/client/utils/cookies";

type UseConsentCookieReset = {
  resetCookie: () => void;
};

export const useConsentCookiesReset = (): UseConsentCookieReset => {
  const resetCookie = () => {
    cookiesService.remove(CONSENT_COOKIE_KEY);
    window.location.reload();
  };

  return { resetCookie };
};
