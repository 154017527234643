"use client";
// Components
import { ConsentCookies } from "@/client/domains/user/consent-cookies";

// Services
import "@/client/services/gtm";

export const ClientInit = () => {
  return <ConsentCookies />;
};
