// Core
import { useTranslation } from "react-i18next";

// Components
import { Text } from "@/client/components/ui/Typography/Text";
import { List } from "@/client/components/ui/List";
import { Button } from "@/client/components/ui/Button";
import { Icon } from "@/client/components/ui/Icon";

// Hooks
import { useToggle } from "@/client/hooks/use-toggle";

// Utils
import st from "./styles.module.css";

type ConsentCookiesViewProps = {
  description: string;
  cookieType: "necessary" | "analytical" | "marketing" | "functional";
};

export const ConsentCookiesView = (props: ConsentCookiesViewProps) => {
  const { description, cookieType } = props;
  const { t } = useTranslation("cookies");
  const { isToggle: isExpanded, onToggle: setIsExpanded } = useToggle(false);

  const cookies: string[][] = t(`${cookieType}.table`, { returnObjects: true });
  const columns = cookies.shift();

  return (
    <div className={st["consent-cookies-view"]} data-testid="consent-cookies-view">
      <Text color="dark" tag="p">
        {description}
      </Text>
      {isExpanded && (
        <List
          itemLayout="horizontal"
          split={false}
          dataSource={cookies}
          className={st["consent-cookies-view__list"]}
          renderItem={(cookie) => (
            <List.Item>
              {cookie.map((value, idx) => {
                return (
                  <Text key={value} color="dark" tag="p">
                    {cookie.length - 1 !== idx && `${columns?.[idx]}:`} {`${value}`}
                  </Text>
                );
              })}
            </List.Item>
          )}
        ></List>
      )}
      <Button
        size="small"
        type="link"
        noStyle
        onClick={() => setIsExpanded(!isExpanded)}
        data-testid="consent-cookies-view-toggle"
      >
        <Text size="12">{isExpanded ? t("consentCookies.hide") : t("consentCookies.show")}</Text>
        <Icon name={isExpanded ? "ArrowUpCounter" : "ArrowDownCounter"} size={"12"} />
      </Button>
    </div>
  );
};
