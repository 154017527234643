"use client";
// Core
import { useTranslation } from "react-i18next";
import { usePathname } from "next/navigation";

// Components
import { Button } from "@/client/components/ui/Button";
import { LayoutAppHeader3Cols } from "@/client/components/layouts/layout-app-header-3cols";

// Definitions
import { landingRoutes, routes } from "@/client/definitions/book";

// Utils
import { TFunction } from "i18next";

const RegisterButton = ({ t }: { t: TFunction<"buttons"> }) => {
  return (
    <Button type="primary" size="large" href={routes.signup}>
      {t("buttons:registration")}
    </Button>
  );
};
const SigninButton = ({ t }: { t: TFunction<"buttons"> }) => {
  return (
    <Button type="primary" size="large" href={routes.signin}>
      {t("buttons:signIn")}
    </Button>
  );
};

export const HeaderPublic = () => {
  const { t } = useTranslation("buttons");
  const pathname = usePathname();
  const ghost = landingRoutes.includes(pathname);

  const authRoutes = pathname === routes.signup || pathname === routes.signin;
  if (authRoutes) {
    const button = pathname === routes.signup ? <SigninButton t={t} /> : <RegisterButton t={t} />;
    return <LayoutAppHeader3Cols userMenu={button} ghost={ghost} />;
  }

  return (
    <LayoutAppHeader3Cols
      userMenu={
        <>
          <RegisterButton t={t} />
          <SigninButton t={t} />
        </>
      }
      ghost={ghost}
    />
  );
};
