"use client";

// Components
import { LayoutAppFooter4Cols } from "@/client/components/layouts/layout-app-footer-4cols";
import { FooterNavMenu } from "@/client/components/page-elements/footer-nav-menu";

// Utils
import {
  aboutBlockDataModel,
  customerServiceDataModel,
  supportBlockDataModel,
} from "@/client/definitions/footer";

export const FooterPrivate = () => {
  return (
    <LayoutAppFooter4Cols
      left={<FooterNavMenu data={aboutBlockDataModel} data-testid="footer-navigation-about" />}
      center={
        <FooterNavMenu data={supportBlockDataModel} data-testid="footer-navigation-support" />
      }
      right={
        <FooterNavMenu
          data={customerServiceDataModel}
          data-testid="footer-navigation-customer-service"
        />
      }
    />
  );
};
