// Core
import TagManager from "react-gtm-module";

// Definitions
import { gtmConfig } from "./config";
import type { GTMConfigType } from "./types";

class GTMService {
  private readonly tagManager?: typeof TagManager;

  private readonly isSafeToInit: boolean = typeof window !== "undefined";

  constructor(config: GTMConfigType) {
    if (this.isSafeToInit) {
      const tagManagerArgs = {
        gtmId: config.gtmId,
      };

      this.tagManager = TagManager;
      this.tagManager.initialize(tagManagerArgs);
    }
  }
}

export const gtmService = new GTMService(gtmConfig);
