// Core
import { forwardRef } from "react";
import cx from "classnames";

// Definitions
import { IInputSwitchProps } from "./InputSwitch.types";

// Components
import { Switch } from "antd";
import { Text } from "../../Typography/Text";

// Utils
import st from "./styles.module.css";

export const InputSwitch = forwardRef<HTMLButtonElement, IInputSwitchProps>((props, ref) => {
  const {
    loadingFetch = false,
    disabled = false,
    size,
    textAlign = "right",
    additionalText,
    ...rest
  } = props;

  const checkedValue = typeof rest.checked === "boolean" ? rest.checked : rest?.value;

  return (
    <div className={cx(st.switch)}>
      {textAlign === "left" && additionalText && (
        <Text
          color="gray-900"
          size="14"
          font="system"
          alignmentVertical="middle"
          className={st["switch__text-left"]}
        >
          {additionalText}
        </Text>
      )}
      <Switch
        {...rest}
        checked={checkedValue}
        className={st.switch__btn}
        loading={loadingFetch}
        size={size}
        disabled={disabled}
        ref={ref}
      />
      {textAlign === "right" && additionalText && (
        <Text
          color="gray-900"
          size="14"
          font="system"
          alignmentVertical="middle"
          className={st["switch__text-right"]}
        >
          {additionalText}
        </Text>
      )}
    </div>
  );
});
InputSwitch.displayName = "InputSwitch";
