// Definitions
import type { GTMConfigType } from "./types";

// Utils
import { verifyBuild } from "@/client/utils/verify-build";

const serviceActiveDefault = verifyBuild().isProduction;

export const gtmConfig: GTMConfigType = {
  gtmId: String(process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID),
  active: serviceActiveDefault,
};
