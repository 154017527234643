// Core
import { useCallback, useEffect } from "react";

// Definitions
import { ConsentCookiesType } from "../types";

// Hooks
import { useStore } from "@/client/hooks/use-store";

// Utils
import { CONSENT_COOKIE_KEY } from "../constants";
import { cookiesService } from "@/client/utils/cookies";

type UseConsentCookiesType = {
  onAllowAllCookies: () => void;
  onRejectAllCookies: () => void;
  setConsentCookies: (data: ConsentCookiesType) => void;
};

const defaultConsentCookies: ConsentCookiesType = {
  necessary: false,
  analytical: false,
};

export const useConsentCookies = (): UseConsentCookiesType => {
  const {
    consentCookies: { setIsOpenConsentCookies },
  } = useStore();

  useEffect(() => {
    const consentCookies = cookiesService.fromJson<ConsentCookiesType>(CONSENT_COOKIE_KEY);
    const isAcceptedCookies = !!consentCookies;
    let cookieAppearanceTimeout: NodeJS.Timeout;

    if (!isAcceptedCookies) {
      cookieAppearanceTimeout = setTimeout(() => setIsOpenConsentCookies(true), 2000);
    }

    return () => clearTimeout(cookieAppearanceTimeout);
  }, []);

  const setConsentCookies = useCallback((data: ConsentCookiesType) => {
    cookiesService.toJson<ConsentCookiesType>(CONSENT_COOKIE_KEY, data);
    setIsOpenConsentCookies(false);
  }, []);

  const onAllowAllCookies = useCallback(() => {
    setConsentCookies({
      ...defaultConsentCookies,
      necessary: true,
      analytical: true,
    });
  }, []);

  const onRejectAllCookies = useCallback(() => {
    setConsentCookies({
      ...defaultConsentCookies,
      necessary: true,
    });
  }, []);

  return {
    onAllowAllCookies,
    onRejectAllCookies,
    setConsentCookies,
  };
};
